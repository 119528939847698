import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import { Options, Modules } from '@next-common/redux-apollo-helper';


export const createQuery = ({
  footerModule,
}: Modules,
{
  includePersonalQuery = false,
}: Options): DocumentNode | string => {
   const  PAGE_QUERY = gql`query footerQuery($universe: String) {
      seoLinks(seoLinksInput: {universe: $universe}) {
        ...FooterSeoLinks
      }
      ${includePersonalQuery ? `  
        profile {
            ...FooterProfile
        }
      ` : ''
}
}
    ${footerModule?.containers.FooterContainer?.initalFragments.seoLinks}
    ${includePersonalQuery ? footerModule?.containers.FooterContainer?.personalizedFraments.profile : ''}
    `;

  return PAGE_QUERY;
};
