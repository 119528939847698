import { unstable_ClassNameGenerator as ClassNameGenerator } from '@mui/material/className';

ClassNameGenerator.configure(componentName => componentName.replace('Mui', 'V5')); // load as early as possible this code in a js file

/* eslint-disable react/prop-types */
import footerModule from '@next-common/footer';
import '@next-common/footer/build/style.global.css';
import { Context, getServerSidePropsLoader, PagePropsType, regroupModuleReducers } from '@next-common/redux-apollo-helper';

import React from 'react';
import { configService as configurationService } from '@next-common/config-service';
import { renderReact } from '../../fragmentHelper/renderReactCustom';
import { createQuery } from './PageQuery';
import { ReduxApolloFragmentWrapperChildren } from '../../fragmentHelper/Wrapper';
import { GetServerSidePropsHypernovaWatchContext } from '../../types';

const config = configurationService.get();

export { footerModule };

const { FooterContainer }: any = footerModule.containers;

const graphQlQuery = createQuery(
  {
    footerModule,
  },
  {
    // includePersonalQuery: config.featureFlipping.ssrPersonalize,
    includePersonalQuery: config.featureFlipping.ssrPersonalize || typeof window !== 'undefined',
    
  }
);

const reducers = regroupModuleReducers([footerModule]);

const WrappedFooter: React.FC<PagePropsType & { graphQlvariables: any }> = ({
  initialApolloState,
  initialReduxState,
  moduleName,
  graphQlvariables,
}) => {
  const graphQlOptions = {
    variables: graphQlvariables,
  };
  return (
    <ReduxApolloFragmentWrapperChildren
      initialApolloState={initialApolloState}
      initialReduxState={initialReduxState}
      moduleName={moduleName}
      reducers={reducers}
    >
      <FooterContainer showClassicPageLink={true} PERSONALIZED_QUERY_OPTION={graphQlOptions} PERSONALIZED_QUERY={graphQlQuery} />
    </ReduxApolloFragmentWrapperChildren>
  );
};

export const renderFunction = renderReact('Footer', WrappedFooter);

const modules = [footerModule];

export const serverCanCache = !config.featureFlipping.ssrPersonalize;

export const getServerSideProps = async (watchContext: GetServerSidePropsHypernovaWatchContext) => {
  const baseUrl = null;
  const preloadedConfig = null;
  const graphQlvariables = { universe: watchContext.jobData.universe };
  const { initialReduxState, initialApolloState } = await getServerSidePropsLoader(
    watchContext,
    modules,
    baseUrl,
    preloadedConfig,
    graphQlQuery,
    graphQlvariables
  );

  return {
    props: {
      graphQlvariables,
      initialReduxState,
      initialApolloState,
    },
  };
};
